
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ICenarioFiscalDefinicao, ICenarioFiscalDefinicaoCofins } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import CenarioFiscalDefinicaoCofins from './CenarioFiscalDefinicaoCofins.vue';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarTipoItemCenarioFiscal from '../SelecionarTipoItemCenarioFiscal.vue';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'CenarioFiscalDefinicaoCofinsModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    definicao: {
      type: Object as () => ICenarioFiscalDefinicao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CenarioFiscalDefinicaoCofins,
    SelecionarTipoItemCenarioFiscal,
    SelecionarProduto,
    SelecionarNcm,
  },
  emits: ['update:visivel', 'confirmar'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoCenarioFiscal = new ServicoCenarioFiscal();

    const state = reactive({
      definicao: {} as ICenarioFiscalDefinicao,
    });

    function limparTela() {
      const definicao = {} as ICenarioFiscalDefinicao;
      definicao.codigo = 0;
      definicao.codigoCenarioFiscal = 0;
      definicao.codigoEstado = 0;
      definicao.codigoNcm = 0;
      definicao.codigosNcms = [] as number[];
      definicao.codigoProdutoDefinicao = 0;
      definicao.codigosDefinicoesProdutos = [] as number[];
      definicao.tipoItem = ETipoItemCenarioFiscal.Produto;
      definicao.tipoImposto = ETipoImposto.Cofins;
      definicao.visivel = true;
      definicao.definicaoCofins = {} as ICenarioFiscalDefinicaoCofins;
      state.definicao = definicao;
    }

    function salvar() {
      const validacao = servicoCenarioFiscal.validarDefinicao(state.definicao);
      if (validacao.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemAlerta(validacao.mensagem);
      }

      emit('confirmar', servicoCenarioFiscal.obterDefinicoesPreparadas(state.definicao, props.definicao.codigo === -1));
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.definicao.codigo !== -1) {
          state.definicao = props.definicao;
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      ETipoItemCenarioFiscal,
      salvar,
    };
  },
});
