import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = { class: "ant-row ss-margin-campos" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_7 = { class: "ant-row" }
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_9 = { class: "ant-row" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_13 = { class: "ant-col ant-col-xs-20 ant-col-xl-20" }
const _hoisted_14 = { class: "ant-row" }
const _hoisted_15 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_16 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_17 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_18 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_19 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_20 = { class: "ant-row" }
const _hoisted_21 = { class: "ant-col ant-col-xs-8 ant-col-xl-8" }
const _hoisted_22 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_23 = { class: "ant-col ant-col-xs-5 ant-col-xl-5" }
const _hoisted_24 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_selecionar_tipo_atividade = _resolveComponent("selecionar-tipo-atividade")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_card = _resolveComponent("card")!
  const _component_selecionar_cfop = _resolveComponent("selecionar-cfop")!
  const _component_selecionar_origem_mercadoria = _resolveComponent("selecionar-origem-mercadoria")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_selecionar_cst_icms = _resolveComponent("selecionar-cst-icms")!
  const _component_selecionar_csosn = _resolveComponent("selecionar-csosn")!
  const _component_selecionar_modalidade_base_calculo_icms = _resolveComponent("selecionar-modalidade-base-calculo-icms")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_cenario_fiscal_informacao_complementar = _resolveComponent("cenario-fiscal-informacao-complementar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, { titulo: "DETALHES DA PESSOA ENVOLVIDA NA OPERAÇÃO" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_form_item, {
              label: "Tipo de atividade",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_selecionar_tipo_atividade, {
                  varias: true,
                  codigosSelecionados: _ctx.computedTiposAtividade,
                  "onUpdate:codigosSelecionados": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedTiposAtividade) = $event))
                }, null, 8, ["codigosSelecionados"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.computedDefinicao.definicaoIcms.contribuinteIcms,
              "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.contribuinteIcms) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Contribuinte de ICMS")
              ]),
              _: 1
            }, 8, ["checked"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.computedDefinicao.definicaoIcms.naoContribuinteIcms,
              "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.naoContribuinteIcms) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Não contribuinte")
              ]),
              _: 1
            }, 8, ["checked"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.computedDefinicao.definicaoIcms.pessoaSuperSimples,
              "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.pessoaSuperSimples) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Optante pelo Simples Nacional")
              ]),
              _: 1
            }, 8, ["checked"])
          ])
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_a_form_item, {
          label: "CFOP - Natureza da operação",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_cfop, {
              codigoSelecionado: _ctx.computedDefinicao.definicaoIcms.codigoCfop,
              "onUpdate:codigoSelecionado": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.codigoCfop) = $event))
            }, null, 8, ["codigoSelecionado"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_a_form_item, {
          label: "Origem da Mercadoria",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_origem_mercadoria, {
              codigoSelecionado: _ctx.computedDefinicao.definicaoIcms.origemMercadoria,
              "onUpdate:codigoSelecionado": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.origemMercadoria) = $event))
            }, null, 8, ["codigoSelecionado"])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_a_form_item, {
          label: " ",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_group, {
              value: _ctx.computedDefinicao.definicaoIcms.definicaoCst,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.definicaoCst) = $event)),
              "button-style": "solid"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_button, { value: true }, {
                  default: _withCtx(() => [
                    _createTextVNode("CST")
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_radio_button, { value: false }, {
                  default: _withCtx(() => [
                    _createTextVNode("CSOSN")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_a_form_item, {
          label: _ctx.computedDefinicao.definicaoIcms.definicaoCst ? 'CST' : 'CSOSN',
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            (_ctx.computedDefinicao.definicaoIcms.definicaoCst)
              ? (_openBlock(), _createBlock(_component_selecionar_cst_icms, {
                  key: 0,
                  codigoSelecionado: _ctx.computedDefinicao.definicaoIcms.cstIcms,
                  "onUpdate:codigoSelecionado": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.cstIcms) = $event))
                }, null, 8, ["codigoSelecionado"]))
              : (_openBlock(), _createBlock(_component_selecionar_csosn, {
                  key: 1,
                  codigoSelecionado: _ctx.computedDefinicao.definicaoIcms.cson,
                  "onUpdate:codigoSelecionado": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.cson) = $event))
                }, null, 8, ["codigoSelecionado"]))
          ]),
          _: 1
        }, 8, ["label"])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_a_form_item, {
          label: "Modalidade da B.Cálculo do ICMS",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_selecionar_modalidade_base_calculo_icms, {
              codigoSelecionado: _ctx.computedDefinicao.definicaoIcms.modalidadeBaseCalculoIcms,
              "onUpdate:codigoSelecionado": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.modalidadeBaseCalculoIcms) = $event))
            }, null, 8, ["codigoSelecionado"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_a_form_item, {
          label: "Alíquota do ICMS",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoIcms.aliquotaIcms,
              "onUpdate:valor": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.aliquotaIcms) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_a_form_item, {
          label: "Redução da B.C",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoIcms.reducaoBaseCalculoIcms,
              "onUpdate:valor": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.reducaoBaseCalculoIcms) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_a_form_item, {
          label: "Alíquota FCP",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoIcms.aliquotaFcp,
              "onUpdate:valor": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.aliquotaFcp) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_a_form_item, {
          label: "Código de Benefício Fiscal",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.codigoBeneficioFiscal) = $event)),
              class: "ant-input"
            }, null, 512), [
              [_vModelText, _ctx.computedDefinicao.definicaoIcms.codigoBeneficioFiscal]
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_a_form_item, {
          label: "Calcular DIFAL",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_group, {
              value: _ctx.computedDefinicao.definicaoIcms.calcularIcmsInterestadual,
              "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.calcularIcmsInterestadual) = $event)),
              "button-style": "solid"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_button, { value: true }, {
                  default: _withCtx(() => [
                    _createTextVNode("Sim")
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_radio_button, { value: false }, {
                  default: _withCtx(() => [
                    _createTextVNode("Não")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_a_form_item, {
          label: "Alíquota Interestadual",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoIcms.icmsInterestadual.aliquotaInterestadual,
              "onUpdate:valor": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.icmsInterestadual.aliquotaInterestadual) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_a_form_item, {
          label: "Alíquota da UF de Destino",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoIcms.icmsInterestadual.aliquotaInternaIcmsUfDestino,
              "onUpdate:valor": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.icmsInterestadual.aliquotaInternaIcmsUfDestino) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_a_form_item, {
          label: "Redução da B.C do Destino",
          class: "ss-margin-campos"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_campo_numerico, {
              percentual: true,
              valor: _ctx.computedDefinicao.definicaoIcms.icmsInterestadual.reducaoBaseCalculoUfDestino,
              "onUpdate:valor": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.icmsInterestadual.reducaoBaseCalculoUfDestino) = $event))
            }, null, 8, ["valor"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_cenario_fiscal_informacao_complementar, {
      "informacao-complementar": _ctx.computedDefinicao.definicaoIcms.informacaoComplementar,
      "onUpdate:informacaoComplementar": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.computedDefinicao.definicaoIcms.informacaoComplementar) = $event))
    }, null, 8, ["informacao-complementar"])
  ]))
}